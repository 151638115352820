import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verify, clearErrors } from "../actions/auth";

const useActivate = (uid, token, apiUrl) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const error = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);
    const isStatus204 = status === 204;

    useEffect(() => {
        // Clear errors when the component unmounts
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch]);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            setLoading(true);

            try {
                await dispatch(verify(uid, token, apiUrl));
            } catch (error) {
                // handle login error
                console.error("Error submitting form");
            } finally {
                setLoading(false);
            }
        },
        [dispatch, uid, token, apiUrl]
    );

    return {
        loading,
        error,
        onSubmit: handleSubmit,
        status,
        isStatus204,
    };
};

export default useActivate;
