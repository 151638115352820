import React, { createContext, useState, useContext } from "react";
import ToastContainer from "react-bootstrap/ToastContainer";
import ToastMsg from "../../components/cards/utilities/toastSuccess";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    // Add a new toast
    const addToast = (toast) => {
        const existingToast = toasts.find(
            (t) => t.message === toast.message && t.bg === toast.bg
        );

        if (existingToast) {
            // If a toast with the same message and style exists, just update its `show` state.
            setToasts((prev) =>
                prev.map((t) =>
                    t.id === existingToast.id ? { ...t, show: true } : t
                )
            );
        } else {
            // Add a new toast if it doesn't already exist
            setToasts((prev) => [
                ...prev,
                { ...toast, id: Date.now(), show: true },
            ]);
        }
    };

    // Hide a toast instead of removing it immediately
    const hideToast = (id) => {
        setToasts((prev) =>
            prev.map((toast) =>
                toast.id === id ? { ...toast, show: false } : toast
            )
        );

        // Remove the toast after a delay to allow it to fade out
        setTimeout(() => {
            setToasts((prev) => prev.filter((toast) => toast.id !== id));
        }, 500); // Adjust this to match your CSS fade-out timing
    };

    return (
        <ToastContext.Provider value={{ addToast, hideToast }}>
            {children}
            <ToastContainer
                // position="position-static"
                style={{
                    zIndex: 999999,
                    position: "fixed",
                    top: "1rem",
                    right: "1rem",
                }}
            >
                {toasts.map((toast) => (
                    <ToastMsg
                        key={toast.id}
                        onClose={() => hideToast(toast.id)}
                        message={toast.message}
                        Toastheader={toast.Toastheader}
                        show={toast.show}
                        bg={toast.bg || "light"}
                        index={toast.id}
                    />
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
};

// Hook for accessing the Toast Context
export const useToast = () => useContext(ToastContext);
