import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import NoPage from "../components/pages/NoPage";
import { PageLoader } from "../components/cards/utilities/loader";
import StudentDashboardLayout from "../components/pages/student dashboard/layout";
import StudentDashboard from "../components/pages/student dashboard/dashboard";
import StudentPayments from "../components/pages/student dashboard/payments";
import Courses from "../components/pages/student dashboard/courses";
import StudentCourseDetail from "../components/pages/student dashboard/detail pages/studentCourseDetail";
import StudentTrackDetail from "../components/pages/student dashboard/detail pages/studentTrackDetail";
import MyCourses from "../components/pages/student dashboard/myCourses";
import Profile from "../components/pages/student dashboard/Profile/profile";
import Events from "../components/pages/student dashboard/events/event";
import Layout from "../components/pages/layout";
// import Home from "../components/pages/Home";
// import About from "../components/pages/About";
// import Services from "../components/pages/Services";
// import ServicesDetail from "../components/detailedPages/servicesDetail";
// import RecentJob from "../components/pages/RecentJobs";
// import RecentJobDetail from "../components/detailedPages/recentJobDetail";
// import Course from "../components/pages/Course";
// import CourseDetail from "../components/detailedPages/courseDetail";
// import TrackDetail from "../components/detailedPages/trackDetail";
import Privacy from "../components/pages/PrivacyPolicy";
import OurReturnPolicy from "../components/pages/ReturnPolicy";
import OurTerms from "../components/pages/TermsAndConditions";
// import Blog from "../components/pages/blog/blog";
// import BlogDetail from "../components/pages/blog/blogDetail";
import Contact from "../components/pages/Contact";
import Careers from "../components/pages/Careers";
import JobOpeningDetail from "../components/detailedPages/jobOpeningDetail";
import LogIn from "../components/pages/authentications/LogIn";
import SignUp from "../components/pages/authentications/SignUp";
import ResetPassword from "../components/pages/authentications/RestPassword";
import NewPasswordConfirm from "../components/pages/authentications/NewPasswordSetup";
import ActivateAccount from "../components/pages/authentications/Activate";
import AuthChecker from "../components/pages/authentications/AuthChecker";

const BlogDetail = lazy(() => import("../components/pages/blog/blogDetail"));
const Blog = lazy(() => import("../components/pages/blog/blog"));
const Home = lazy(() => import("../components/pages/Home"));
const About = lazy(() => import("../components/pages/About"));
const Services = lazy(() => import("../components/pages/Services"));
const ServicesDetail = lazy(() =>
    import("../components/detailedPages/servicesDetail")
);
const RecentJob = lazy(() => import("../components/pages/RecentJobs"));
const RecentJobDetail = lazy(() =>
    import("../components/detailedPages/recentJobDetail")
);
const Course = lazy(() => import("../components/pages/Course"));
const CourseDetail = lazy(() =>
    import("../components/detailedPages/courseDetail")
);
const TrackDetail = lazy(() =>
    import("../components/detailedPages/trackDetail")
);

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        errorElement: <NoPage />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <Home />
                    </Suspense>
                ),
            },
            {
                path: "about",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <About />
                    </Suspense>
                ),
            },
            {
                path: "services",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <Services />
                    </Suspense>
                ),
            },
            {
                path: "services/:slug",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <ServicesDetail />
                    </Suspense>
                ),
            },
            {
                path: "recent-jobs",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <RecentJob />
                    </Suspense>
                ),
            },
            {
                path: "recent-jobs/:slug",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <RecentJobDetail />
                    </Suspense>
                ),
            },
            {
                path: "contact",
                element: <Contact />,
            },
            {
                path: "privacy-policy",
                element: <Privacy />,
            },
            {
                path: "return-policy",
                element: <OurReturnPolicy />,
            },
            {
                path: "terms-and-conditions",
                element: <OurTerms />,
            },
            {
                path: "careers",
                element: <Careers />,
            },
            {
                path: "careers/:slug",
                element: <JobOpeningDetail />,
            },
            {
                path: "track",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <Course />
                    </Suspense>
                ),
            },
            {
                path: "courses",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <Course />
                    </Suspense>
                ),
            },
            {
                path: "courses/:slug",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <CourseDetail />
                    </Suspense>
                ),
            },
            {
                path: "track/:slug",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <TrackDetail />
                    </Suspense>
                ),
            },
            {
                path: "blog",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <Blog />
                    </Suspense>
                ),
            },
            {
                path: "blog/:slug",
                element: (
                    <Suspense fallback={<PageLoader />}>
                        <BlogDetail />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: "/",
        errorElement: <NoPage />,
        children: [
            {
                path: "login",
                element: <LogIn />,
            },
            {
                path: "create-account",
                element: <SignUp />,
            },
            {
                path: "reset-password",
                element: <ResetPassword />,
            },
            {
                path: "password/reset/confirm/:uid/:token",
                element: <NewPasswordConfirm />,
            },
            {
                path: "activate/:uid/:token",
                element: <ActivateAccount />,
            },
        ],
    },
    {
        path: "/student/dashboard",
        element: (
            <AuthChecker>
                <StudentDashboardLayout />
            </AuthChecker>
        ),
        errorElement: <NoPage />,
        children: [
            {
                index: true,
                element: <StudentDashboard />,
            },
            {
                path: "payments",
                element: <StudentPayments />,
            },
            {
                path: "courses",
                element: <Courses />,
            },
            {
                path: "courses/:slug",
                element: <StudentCourseDetail />,
            },
            {
                path: "track/:slug",
                element: <StudentTrackDetail />,
            },
            {
                path: "my-courses",
                element: <MyCourses />,
            },
            {
                path: "profile",
                element: <Profile />,
            },
            {
                path: "event",
                element: <Events />,
            },
        ],
    },
]);
