import CourseCard from "../../cards/courseCard";
import { CourseContext, CourseTrackContext } from "../../../App";
import { useContext } from "react";
import { Link } from "react-router-dom";
import HeaderBar from "./components/headerBar";
import DashCourseCard from "./Courses/dashCourseCard";

const Courses = () => {
    const courses = useContext(CourseContext);
    const tracks = useContext(CourseTrackContext);
    console.log(courses);
    console.log(tracks);
    return (
        <section>
            <HeaderBar pageHeader="Our Courses" />

            <main className="container">
                <section className="mb-8">
                    <header className="text-center mb-4">
                        <h2>Our Tracks</h2>
                        <h6>See Our Exceptional Tracks</h6>
                    </header>
                    <section className="row row-cols-1 row-cols-lg-3 g-6 justify-content-center">
                        {tracks.map((item) => (
                            <Link
                                className="text-decoration-none"
                                to={"/student/dashboard/track/" + item.slug}
                            >
                                <DashCourseCard
                                    title={item.title}
                                    level={item.level}
                                    duration={item.duration}
                                    payment={item.payment_type}
                                    image={item.track_img_url}
                                    id={item.id}
                                    is_track={true}
                                    presence={item.presence.name}
                                />
                            </Link>
                        ))}
                    </section>
                </section>

                <section>
                    <header className="text-center mb-4">
                        <h2>Our Courses</h2>
                        <h6>See Our Exceptional Courses</h6>
                    </header>
                    <section className="row row-cols-1 row-cols-lg-3 g-6 justify-content-center">
                        {courses.map((item) => (
                            <Link
                                className="text-decoration-none"
                                to={"/student/dashboard/courses/" + item.slug}
                            >
                                <DashCourseCard
                                    title={item.title}
                                    level={item.level}
                                    duration={item.duration}
                                    payment={item.payment_type}
                                    image={item.track_img_url}
                                    id={item.id}
                                    is_track={false}
                                    presence={item.presence.name}
                                />
                            </Link>
                        ))}
                    </section>
                </section>
            </main>
        </section>
    );
};

export default Courses;
