import Toast from "react-bootstrap/Toast";

const ToastMsg = ({ onClose, message, Toastheader, show, bg, index }) => {
    return (
        <Toast
            onClose={onClose}
            show={show}
            delay={10000}
            autohide
            bg={bg}
            style={{
                maxWidth: "fit-content",
                minWidth: "auto",
                padding: "0 .5rem",
            }}
            key={index}
        >
            {/* <Toast.Header>
                <strong className="me-auto">{Toastheader}yyy</strong>
            </Toast.Header> */}
            <Toast.Body className={bg !== "white" && "text-white"}>
                <section className="d-flex align-items-center">
                    <button
                        type="button"
                        className="btn-close me-2"
                        aria-label="Close"
                        onClick={onClose}
                        style={{ filter: "invert(1)" }}
                    ></button>
                    <section>{message}</section>
                </section>
            </Toast.Body>
        </Toast>
    );
};

export default ToastMsg;
