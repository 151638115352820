import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback, useState } from "react";
import { googleAuthenticate, clearErrors } from "../actions/auth";
import axios from "axios";

const useGoogleAuth = (apiUrl) => {
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const error = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);
    const [loading, setLoading] = useState(false);
    const isStatus201 = status === 201;

    // Initiate Google Login
    const continueWithGoogle = useCallback(async () => {
        setLoading(true);

        const redirectUri = `${window.location.origin}/login`; // Callback URL

        try {
            // Fetch Google's authorization URL
            const res = await axios.get(
                `${apiUrl}/auth/o/google-oauth2/?redirect_uri=${redirectUri}`
            );

            // Redirect user to Google's OAuth page
            window.location.replace(res.data.authorization_url);
        } catch (err) {
            console.error("Google Auth Failed", err);
        } finally {
            setLoading(false);
        }
    }, [apiUrl]);

    // Authenticate with Google after redirection
    const authenticateWithGoogle = useCallback(
        async (state, code) => {
            if (!state || !code) return;

            setLoading(true);
            try {
                await dispatch(googleAuthenticate(state, code, apiUrl));
            } catch (err) {
                console.error("Authentication failed", err);
            } finally {
                setLoading(false);
            }
        },
        [apiUrl, dispatch]
    );

    // Clear errors when the hook is unmounted
    useEffect(() => {
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch]);

    return {
        continueWithGoogle,
        authenticateWithGoogle,
        isAuthenticated,
        error,
        isStatus201,
        loading,
    };
};

export default useGoogleAuth;
