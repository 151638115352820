import useAuthicatedUser from "../../../hooks/useLoadUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useToast } from "../../../hooks/otherHooks/useToast";

const apiURL = process.env.REACT_APP_API_URL;

const AuthChecker = ({ children }) => {
    const location = useLocation();
    // Check Auth and Load user
    const { isAuthenticated } = useAuthicatedUser(apiURL, location);

    // Toast
    const { addToast } = useToast();

    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated === false) {
            // Add toast only once
            addToast({
                message: "Session Expired. Log in to continue",
                bg: "danger",
            });

            // Navigate appropriately
            const redirectPath =
                location.pathname !== "/auth/login"
                    ? `/login?ReturnUrl=${location.pathname}`
                    : "/login";
            navigate(redirectPath);
        }
    }, [isAuthenticated, navigate, location.pathname, addToast]);

    return (
        <section>
            <section>{children}</section>
        </section>
    );
};

export default AuthChecker;
