import HeaderBar from "../components/headerBar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Events = () => {
    return (
        <section>
            <HeaderBar pageHeader="Events"></HeaderBar>
            <section className="container">
                <section className="container card no-card-style">
                    <section className="px-2 py-6">
                        <FullCalendar
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                            events={[
                                { title: "event 1", date: "2024-09-27" },
                                { title: "event 2", date: "2024-09-28" },
                                { title: "event 3", date: "2024-09-28" },
                            ]}
                        />
                    </section>
                </section>
            </section>
        </section>
    );
};

export default Events;
