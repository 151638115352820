import TopBar from "./topBar";
import StudentDashboardFooter from "./components/footer";
import ScrollToTop from "../../cards/utilities/scrollToTop";

const StudentDashboardLayout = () => {
    return (
        <section>
            <ScrollToTop />
            <TopBar />
        </section>
    );
};

export default StudentDashboardLayout;
