import Nav from "react-bootstrap/Nav";
import { useState, useEffect } from "react";
import HeaderBar from "./components/headerBar";
import CompletedCourses from "./Courses/completedCourses";
import InProgressCourses from "./Courses/inProgressCourse";
import axios from "axios";

const MyCourses = () => {
    const [activeTab, setActiveTab] = useState("#in-progress");
    const [enrollmentData, setEnrollmentData] = useState([]);

    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    const fetchEnrollment = async () => {
        if (localStorage.getItem("access")) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("access")}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/course/bk-online-enrollment`,
                    config
                );
                setEnrollmentData(response.data);
            } catch (error) {
                console.error("Failed to fetch Payment History:", error);
            } finally {
                // setLoading(null);
            }
        } else {
            console.error("User not authenticated");
            return [];
        }
    };

    useEffect(() => {
        fetchEnrollment();
    }, []);

    // Filtering completed enrollments
    const completedCourses = enrollmentData.filter(
        (enrollment) => enrollment.is_completed
    );

    // Filtering incomplete enrollments
    const incompleteCourses = enrollmentData.filter(
        (enrollment) => !enrollment.is_completed
    );

    return (
        <section className="">
            <HeaderBar pageHeader="My Courses">
                <Nav
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    className=""
                >
                    <Nav.Item className="border border-2 border-primary me-2">
                        <Nav.Link eventKey="#in-progress">In Progress</Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="border border-2 border-primary">
                        <Nav.Link eventKey="#completed">Completed</Nav.Link>
                    </Nav.Item>
                </Nav>
            </HeaderBar>
            <section className="container">
                {activeTab === "#in-progress" && (
                    <InProgressCourses inProgressData={incompleteCourses} />
                )}

                {activeTab === "#completed" && (
                    <CompletedCourses completedData={completedCourses} />
                )}
            </section>
        </section>
    );
};

export default MyCourses;
