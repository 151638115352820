import { CompanyInfoContext } from "../../../App";
import { useContext, useEffect } from "react";
import { extractErrorMessages } from "./ExtractErrorMsg";
import { useToast } from "../../../hooks/otherHooks/useToast";
import ScrollToTop from "../../cards/utilities/scrollToTop";

const pic = {
    url: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwallpaperaccess.com%2Ffull%2F2221274.jpg&f=1&nofb=1&ipt=f0e2873b75a0926044a35fa7eed917b2890c5ff62d0b1da4cd4f9f1db5e54ee3&ipo=images",
};

//npm install --save redux @redux-devtools/extension react-redux redux-thunk @reduxjs/toolkit

// Utility function to extract error messages

export const myStyle = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 61, 43, 0.5), rgba(0, 61, 43, 0.5)), url(${pic.url})`,
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundAttachment: "fixed",
};

const AuthUITemplate = ({ children, errorMsg, title }) => {
    const companyInfo = useContext(CompanyInfoContext);

    const { addToast } = useToast();

    useEffect(() => {
        if (errorMsg) {
            const extractedMessages = extractErrorMessages(errorMsg);
            extractedMessages.map((message) =>
                addToast({
                    message: message,
                    bg: "danger",
                })
            );
        }
    }, [errorMsg]);

    return (
        <section style={myStyle}>
            <ScrollToTop />
            <section
                className="container min-vh-100 py-10 d-flex justify-content-center align-items-center"
                style={{ maxWidth: "515px" }}
            >
                <section className="container card auth-card py-8 px-4">
                    <header className="mx-auto mb-3 text-center">
                        <h4>{title}</h4>
                    </header>

                    {children}
                </section>
            </section>
        </section>
    );
};

export default AuthUITemplate;
