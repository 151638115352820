import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import SearchForm from "../../../cards/utilities/searchForm";
import Form from "react-bootstrap/Form";
import usePagination from "../../../../hooks/usePagination";
import axios from "axios";

const PaymentHistory = () => {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const fetchHistory = async () => {
        if (localStorage.getItem("access")) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("access")}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/course/bk-payment-history`,
                    config
                );
                setPaymentHistory(response.data);
            } catch (error) {
                console.error("Failed to fetch Payment History:", error);
            } finally {
                // setLoading(null);
            }
        } else {
            console.error("User not authenticated");
            return [];
        }
    };

    useEffect(() => {
        fetchHistory();
    }, []);

    // Handle individual row selection
    const handleRowSelect = (row) => {
        const isSelected = selectedRows.some(
            (selectedRow) => selectedRow.id === row.id
        );

        const newSelectedRows = isSelected
            ? selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
            : [...selectedRows, row];

        setSelectedRows(newSelectedRows);

        // Update the "select all" checkbox state
        if (newSelectedRows.length === paymentHistory.length) {
            setSelectAllChecked(true);
        } else {
            setSelectAllChecked(false);
        }
    };

    // Handle "select all" checkbox
    const handleSelectAll = () => {
        if (selectAllChecked) {
            setSelectedRows([]);
        } else {
            setSelectedRows([...paymentHistory]);
        }
        setSelectAllChecked(!selectAllChecked);
    };

    return (
        <section className="container">
            <section className="row g-3">
                <section className="col-lg-8 align-self-start">
                    <section className="card no-card-style p-4">
                        <header>
                            <h5>Payment History</h5>
                            <p>Review all your transaction history</p>

                            <section className="w-100 mb-4">
                                <SearchForm placeholder="Search Transactions" />
                            </section>
                        </header>

                        <Table bordered hover>
                            <thead>
                                <tr className="bg-primary text-white text-center">
                                    <th>
                                        <Form.Check
                                            type={"checkbox"}
                                            id="checkbox"
                                            checked={selectAllChecked}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    {/* <th>S/N</th> */}
                                    <th>Course</th>
                                    <th className="d-none d-sm-table-cell">
                                        Amount
                                    </th>
                                    <th className="d-none d-sm-table-cell">
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentHistory.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <tr>
                                            <td>
                                                <Form.Check
                                                    type={"checkbox"}
                                                    id="checkbox"
                                                    checked={selectedRows.some(
                                                        (selectedRow) =>
                                                            selectedRow.id ===
                                                            item.id
                                                    )}
                                                    onChange={() =>
                                                        handleRowSelect(item)
                                                    }
                                                />
                                            </td>
                                            {/* <td>{row.id}</td> */}
                                            <td>
                                                <section className="d-flex">
                                                    <img
                                                        src={
                                                            item?.courses
                                                                ?.course_img_url ||
                                                            item?.coursetracks
                                                                ?.track_img_url
                                                        }
                                                        className="align-self-start me-1"
                                                        width="100"
                                                        // height="100"
                                                        alt=""
                                                    />
                                                    <section>
                                                        <h6>
                                                            {item?.courses
                                                                ?.title ||
                                                                item
                                                                    ?.coursetracks
                                                                    ?.title}
                                                        </h6>

                                                        <section
                                                            className="fw-bold bg-light px-2 py-1 rounded cart-course-info"
                                                            style={{
                                                                fontSize:
                                                                    "13px",
                                                            }}
                                                        >
                                                            <span>
                                                                <i class="fa-solid fa-user"></i>{" "}
                                                                <small className="me-2">
                                                                    {
                                                                        item
                                                                            ?.courses
                                                                            ?.level
                                                                    }
                                                                </small>
                                                            </span>

                                                            <span>
                                                                <i class="fa-solid fa-star"></i>{" "}
                                                                <small className="me-2">
                                                                    4.8 (132k
                                                                    Reviews)
                                                                </small>
                                                            </span>
                                                            <span>
                                                                <i class="fa-solid fa-graduation-cap"></i>{" "}
                                                                <small className="me-2">
                                                                    Professional
                                                                    Certificate
                                                                </small>
                                                            </span>

                                                            <span>
                                                                <i class="fa-regular fa-clock"></i>{" "}
                                                                <small>
                                                                    {
                                                                        item
                                                                            ?.courses
                                                                            ?.duration
                                                                    }
                                                                </small>
                                                            </span>
                                                        </section>
                                                    </section>
                                                </section>
                                            </td>
                                            <td className="d-none d-sm-table-cell">
                                                {item.amount}
                                            </td>

                                            <td className="d-none d-sm-table-cell">
                                                {item.status}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </section>
                </section>

                <section className="col-lg-4 align-self-start">
                    <section className="card no-card-style p-4">
                        <h5>Transaction Reciept</h5>
                        <p>Choose a transaction to download the Reciept</p>

                        <section>
                            {Object.keys(selectedRows).length > 0 && (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="bg-primary text-white text-center">
                                            <th>S/N</th>
                                            <th>Courses/Tracks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedRows.map((item, index) => (
                                            <React.Fragment key={item.id}>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {item?.courses?.title ||
                                                            item?.coursetracks
                                                                ?.title}
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </section>
                        <button
                            className={`btn btn-primary ${
                                Object.keys(selectedRows).length === 0 &&
                                `disabled`
                            } `}
                        >
                            Download Transaction Reciept
                        </button>
                    </section>
                </section>
            </section>
        </section>
    );
};

export default PaymentHistory;
