import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const CourseTable = ({ data }) => {
    // const [expandedRow, setExpandedRow] = useState(null);

    // const toggleRow = (rowIndex) => {
    //     setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
    // };
    const dataChart = {
        labels: ["Completed"],
        datasets: [
            {
                label: "Task Status",
                data: [80, 20], // 80% completed, 20% not completed
                backgroundColor: ["#28A745", "#FF6384"],
                hoverBackgroundColor: ["#28A745", "#FF6384"],
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.label}: ${context.raw}%`,
                },
            },
        },
    };

    console.log(data);

    return (
        <>
            {data?.length > 0 ? (
                <Table bordered hover>
                    <thead>
                        <tr className="bg-primary text-white text-center">
                            <th>S/N</th>
                            <th>Course</th>
                            <th className="d-none d-sm-table-cell">Progress</th>
                            <th className="d-none d-sm-table-cell">Action</th>
                            {/* <th></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        <section className="d-flex">
                                            <img
                                                src={
                                                    item?.course
                                                        ?.course_img_url ||
                                                    item?.track?.track_img_url
                                                }
                                                className="align-self-start me-1"
                                                width="100"
                                                // height="100"
                                                alt=""
                                            />
                                            <section>
                                                <h6>
                                                    {item?.course?.title ||
                                                        item?.track?.title}
                                                </h6>

                                                <section
                                                    className="fw-bold bg-light px-2 py-1 rounded cart-course-info"
                                                    style={{
                                                        fontSize: "13px",
                                                    }}
                                                >
                                                    <span>
                                                        <i class="fa-solid fa-user"></i>{" "}
                                                        <small className="me-2">
                                                            {
                                                                item?.course
                                                                    ?.level
                                                            }
                                                        </small>
                                                    </span>

                                                    <span>
                                                        <i class="fa-solid fa-star"></i>{" "}
                                                        <small className="me-2">
                                                            4.8 (132k Reviews)
                                                        </small>
                                                    </span>
                                                    <span>
                                                        <i class="fa-solid fa-graduation-cap"></i>{" "}
                                                        <small className="me-2">
                                                            Professional
                                                            Certificate
                                                        </small>
                                                    </span>

                                                    <span>
                                                        <i class="fa-regular fa-clock"></i>{" "}
                                                        <small>
                                                            {
                                                                item?.course
                                                                    ?.duration
                                                            }
                                                        </small>
                                                    </span>
                                                </section>
                                            </section>
                                        </section>
                                    </td>
                                    <td className="d-none d-sm-table-cell">
                                        <section
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                            }}
                                        >
                                            <Doughnut
                                                data={dataChart}
                                                options={options}
                                                width={300}
                                                height={300}
                                            />
                                        </section>
                                    </td>
                                    <td className="d-none d-sm-table-cell">
                                        View
                                    </td>
                                    {/* <td
                                        onClick={() => toggleRow(index)}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        Render the arrow icon conditionally
                                        {expandedRow === index ? (
                                            <FaChevronUp />
                                        ) : (
                                            <FaChevronDown />
                                        )}
                                    </td> */}
                                </tr>
                                {/* {expandedRow === index && (
                                    <tr>
                                        <td colSpan="5">
                                            <div>
                                                Replace this with whatever content you want to display
                                                <p>
                                                    Additional details for{" "}
                                                    {item.firstName}{" "}
                                                    {item.lastName}.
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                )} */}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <section className="text-center">
                    <i
                        className="fa fa-shopping-cart"
                        style={{
                            fontSize: "50px",
                            color: "#ccc",
                            marginBottom: "20px",
                        }}
                    ></i>
                    <h4>Your Cart is Empty</h4>
                    <p>
                        Browse our courses and add items to your cart to see
                        them here!
                    </p>
                    <Link
                        className="btn btn-primary"
                        to="/student/dashboard/courses"
                    >
                        Explore Courses
                    </Link>
                </section>
            )}
        </>
    );
};

export default CourseTable;
