import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { useCart } from "../../../../hooks/useCart";
import LoaderIcon from "../../../cards/utilities/loader";
import { Link } from "react-router-dom";

const PaymentCart = () => {
    const { cart, totalCost, removeFromCart, loading } = useCart();

    const handleRemoveFromCart = (id, type, presence, title) => {
        removeFromCart(id, type, presence, title);
    };

    return (
        <section className="container">
            <section className="row g-3">
                <section className="col-lg-8 align-self-start">
                    <section className="card no-card-style p-4">
                        <header>
                            <h5 style={{ display: "inline-block" }}>
                                Payment Cart{" "}
                                {cart?.length > 0 && (
                                    <span
                                        class="badge rounded-pill bg-danger"
                                        style={{
                                            border: "2px solid white",
                                        }}
                                    >
                                        {cart?.length}
                                        <span class="visually-hidden">
                                            shopping cart
                                        </span>
                                    </span>
                                )}
                            </h5>
                            <p>Review your shopping Cart</p>
                        </header>
                        {cart?.length > 0 ? (
                            <Table bordered hover>
                                <thead>
                                    <tr className="bg-primary text-white text-center">
                                        <th>Course/Track</th>

                                        <th className="d-none d-sm-table-cell">
                                            Actions
                                        </th>
                                        <th className="d-none d-sm-table-cell">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cart?.map((course, index) => (
                                        <React.Fragment key={course.id}>
                                            <tr>
                                                <td>
                                                    <section className="d-flex">
                                                        <img
                                                            src={
                                                                course.item
                                                                    .course_img_url ||
                                                                course.item
                                                                    .track_img_url
                                                            }
                                                            className="align-self-start me-1"
                                                            width="100"
                                                            // height="100"
                                                            alt=""
                                                        />
                                                        <section>
                                                            <h6>
                                                                {
                                                                    course.item
                                                                        .title
                                                                }
                                                            </h6>

                                                            <section
                                                                className="fw-bold bg-light px-2 py-1 rounded cart-course-info"
                                                                style={{
                                                                    fontSize:
                                                                        "13px",
                                                                }}
                                                            >
                                                                <span>
                                                                    <i class="fa-solid fa-user"></i>{" "}
                                                                    <small className="me-2">
                                                                        {
                                                                            course
                                                                                .item
                                                                                .level
                                                                        }
                                                                    </small>
                                                                </span>

                                                                <span>
                                                                    <i class="fa-solid fa-star"></i>{" "}
                                                                    <small className="me-2">
                                                                        4.8
                                                                        (132k
                                                                        Reviews)
                                                                    </small>
                                                                </span>
                                                                <span>
                                                                    <i class="fa-solid fa-graduation-cap"></i>{" "}
                                                                    <small className="me-2">
                                                                        Professional
                                                                        Certificate
                                                                    </small>
                                                                </span>

                                                                <span>
                                                                    <i class="fa-regular fa-clock"></i>{" "}
                                                                    <small>
                                                                        {
                                                                            course
                                                                                .item
                                                                                .duration
                                                                        }
                                                                    </small>
                                                                </span>
                                                            </section>
                                                        </section>
                                                    </section>
                                                </td>
                                                <td className="d-none d-sm-table-cell text-center">
                                                    {loading ===
                                                        course.item.title && (
                                                        <LoaderIcon />
                                                    )}
                                                    <i
                                                        class="fa-solid fa-trash text-danger"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            handleRemoveFromCart(
                                                                course.item.id,
                                                                course.type,
                                                                course.presence,
                                                                course.item
                                                                    .title
                                                            )
                                                        }
                                                    ></i>
                                                </td>
                                                <td className="d-none d-sm-table-cell ">
                                                    <p className="fw-bold text-primary">
                                                        N{course?.price}
                                                    </p>
                                                    <p>
                                                        <s>{course.username}</s>
                                                    </p>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <section className="text-center">
                                <i
                                    className="fa fa-shopping-cart"
                                    style={{
                                        fontSize: "50px",
                                        color: "#ccc",
                                        marginBottom: "20px",
                                    }}
                                ></i>
                                <h4>Your Cart is Empty</h4>
                                <p>
                                    Browse our courses and add items to your
                                    cart to see them here!
                                </p>
                                <Link
                                    className="btn btn-primary"
                                    to="/student/dashboard/courses"
                                >
                                    Explore Courses
                                </Link>
                            </section>
                        )}
                    </section>
                </section>

                <section className="col-lg-4 align-self-start">
                    {cart?.length > 0 && (
                        <section className="card no-card-style p-4 mb-3">
                            <p className="fw-bold">Total</p>
                            <h5>N{totalCost}</h5>
                            <p>
                                <s>N15,000</s>
                            </p>
                            <button className="btn btn-primary">
                                Checkout
                            </button>
                        </section>
                    )}

                    <section className="card no-card-style p-4">
                        <h5>Instruction for Offline Payments</h5>
                        <p>Choose a transaction to download the Reciept</p>
                    </section>
                </section>
            </section>
        </section>
    );
};

export default PaymentCart;
