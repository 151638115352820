import AuthUITemplate from "./AuthUI";
import LoaderIcon from "../../cards/utilities/loader";
import { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import useActivate from "../../../hooks/useActivate";
import useAuthRedirect from "../../../hooks/useAuthRedirect";
import { useToast } from "../../../hooks/otherHooks/useToast";

const apiURL = process.env.REACT_APP_API_URL;

const ActivateAccount = () => {
    const { uid } = useParams();
    const { token } = useParams();

    const { error, loading, onSubmit, isStatus204 } = useActivate(
        uid,
        token,
        apiURL
    );

    // Toast
    const { showToast, redirectPage } = useAuthRedirect(isStatus204);

    const { addToast } = useToast();

    useEffect(() => {
        if (showToast) {
            addToast({
                message: "Account Activated. Redirecting...",
                bg: "success",
            });
        }
    }, [showToast, addToast]);

    if (redirectPage) {
        return <Navigate to="/login" />;
    }

    return (
        <AuthUITemplate title="Activate your Account" errorMsg={error}>
            <section className="col-12 d-grid">
                <button
                    onClick={onSubmit}
                    type="submit"
                    className={
                        loading ? "btn btn-primary disabled" : "btn btn-primary"
                    }
                >
                    {loading && <LoaderIcon />}
                    Click here to Verify your Account
                </button>
            </section>
        </AuthUITemplate>
    );
};

export default ActivateAccount;
