import React from "react";
import SearchForm from "../../../cards/utilities/searchForm";
import CourseTable from "./table";

const InProgressCourses = ({ inProgressData }) => {
    const inProgressCourses = inProgressData.filter((item) => item.course);

    const inProgressTracks = inProgressData.filter((item) => item.track);

    return (
        <section className="container">
            <section className="card no-card-style p-4 mb-8">
                <header>
                    <h5>Ongoing Tracks</h5>
                    <p>Review all your transaction history</p>

                    {inProgressTracks.length > 0 && (
                        <section className="w-100 mb-4">
                            <SearchForm placeholder="Search Tracks" />
                        </section>
                    )}
                </header>

                <CourseTable data={inProgressTracks} />
            </section>
            <section className="card no-card-style p-4">
                <header>
                    <h5>Ongoing Courses</h5>
                    <p>Review all your transaction history</p>

                    {inProgressCourses.length > 0 && (
                        <section className="w-100 mb-4">
                            <SearchForm placeholder="Search Courses" />
                        </section>
                    )}
                </header>

                <CourseTable data={inProgressCourses} />
            </section>
        </section>
    );
};

export default InProgressCourses;
