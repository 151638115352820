import React, { useState, useEffect } from "react";
import axios from "axios";

const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(null);
    const [totalCost, setTotalCost] = useState(0);
    console.log(cart);

    useEffect(() => {
        fetchCart();
    }, []);

    const fetchCart = async () => {
        if (localStorage.getItem("access")) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("access")}`,
                    Accept: "application/json",
                },
            };

            // setLoading(itemId);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/course/bk-manage-cart`,
                    config
                );
                setCart(response.data.cart_items);
                setTotalCost(response.data.total_cost);
            } catch (error) {
                console.error("Failed to fetch cart:", error);
            } finally {
                // setLoading(null);
            }
        } else {
            console.error("User not authenticated");
            return [];
        }
    };

    const addToCart = async (itemId, itemType, presence, title) => {
        if (localStorage.getItem("access")) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("access")}`,
                    Accept: "application/json",
                },
            };

            setLoading(title);
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/course/bk-manage-cart`,
                    {
                        action: "add",
                        id: itemId,
                        type: itemType,
                        presence: presence,
                    },
                    config
                );
                setCart(response.data.cart_items);
                setTotalCost(response.data.total_cost);
            } catch (error) {
                console.error("Failed to add item to cart:", error);
            } finally {
                setLoading(null);
            }
        } else {
            console.error("User not authenticated");
            return [];
        }
    };

    const removeFromCart = async (itemId, itemType, presence, title) => {
        if (localStorage.getItem("access")) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${localStorage.getItem("access")}`,
                    Accept: "application/json",
                },
            };
            setLoading(title);
            alert(`${title} would be remove from cart`);
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/course/bk-manage-cart`,
                    {
                        action: "remove",
                        id: itemId,
                        type: itemType,
                        presence: presence,
                    },
                    config
                );
                setCart(response.data.cart_items);
                setTotalCost(response.data.total_cost);
            } catch (error) {
                console.error("Failed to remove item from cart:", error);
            } finally {
                setLoading(null);
            }
        } else {
            console.error("User not authenticated");
            return [];
        }
    };

    return (
        <CartContext.Provider
            value={{ cart, loading, addToCart, removeFromCart, totalCost }}
        >
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use Cart context
export const useCart = () => React.useContext(CartContext);
