import { useState, useEffect, cloneElement, isValidElement } from "react";
import ReactPaginate from "react-paginate";

function usePagination({ itemsPerPage, data = [], children }) {
    const [itemOffset, setItemOffset] = useState(0);

    // Ensure data is always an array
    const items = Array.isArray(data) ? data : Object.values(data || []);

    useEffect(() => {
        // Reset pagination when data changes
        setItemOffset(0);
    }, [data]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    const clonedChildren =
        isValidElement(children) && cloneElement(children, { currentItems });

    const paginationUI = (
        <section className="container">
            {clonedChildren}
            {pageCount > 1 && (
                <ReactPaginate
                    previousLabel="Prev"
                    nextLabel="Next"
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    marginPagesDisplayed={3}
                    containerClassName="pagination justify-content-center mt-6"
                    pageClassName="page-item me-2"
                    pageLinkClassName="page-link"
                    previousClassName="page-item me-5"
                    previousLinkClassName="page-link"
                    nextClassName="page-item ms-4"
                    nextLinkClassName="page-link"
                    breakClassName="page-item me-1"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                />
            )}
        </section>
    );

    return { paginationUI };
}

export default usePagination;
