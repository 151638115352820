import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signup, clearErrors } from "../actions/auth";

const useSignup = (initialFields, apiUrl) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(initialFields);

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const error = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);
    const isStatus201 = status === 201;

    useEffect(() => {
        // Clear errors when the component unmounts
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prevFields) => ({ ...prevFields, [name]: value }));
    }, []);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            setLoading(true);

            try {
                await dispatch(signup(formData, apiUrl));
            } catch (error) {
                // handle login error
                console.error("Error submitting form");
            } finally {
                setLoading(false);
            }
        },
        [dispatch, formData, apiUrl]
    );

    return {
        loading,
        formData,
        error,
        onChange: handleChange,
        onSubmit: handleSubmit,
        isAuthenticated,
        status,
        isStatus201,
    };
};

export default useSignup;
