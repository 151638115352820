import { useEffect, useState } from "react";

function useAuthRedirect(isAuthenticated) {
    const [showToast, setShowToast] = useState(false);
    const [redirectPage, setRedirectPage] = useState(false);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    useEffect(() => {
        if (isAuthenticated) {
            setShowToast(true);
            const timer = setTimeout(() => {
                setRedirectPage(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [isAuthenticated]);

    return { showToast, redirectPage, handleCloseToast };
}

export default useAuthRedirect;
