import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset_password, clearErrors } from "../actions/auth";

const useResetPassword = (apiUrl) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const status = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);
    const isStatus204 = status === 204;

    useEffect(() => {
        // Clear errors when the component unmounts
        return () => {
            dispatch(clearErrors());
        };
    }, [dispatch]);

    const handleChange = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
                await dispatch(reset_password(email, apiUrl));
            } catch (err) {
                // You can handle error here if needed
            } finally {
                setLoading(false);
            }
        },
        [dispatch, email]
    );

    return {
        email,
        loading,
        handleChange,
        status,
        error,
        handleSubmit,
        isStatus204,
    };
};

export default useResetPassword;
