import React from "react";
import useGoogleAuth from "../../../hooks/useGoogleAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAuthRedirect from "../../../hooks/useAuthRedirect";
import { useToast } from "../../../hooks/otherHooks/useToast";
import LoaderIcon from "../../cards/utilities/loader";

const apiUrl = process.env.REACT_APP_API_URL;

const GoogleAuthHandler = ({ redirectToBlogURL }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const state = queryParams.get("state");
    const code = queryParams.get("code");

    const blogReturnUrl = localStorage.getItem("blogReturnUrl");

    const { authenticateWithGoogle, continueWithGoogle, loading, isStatus201 } =
        useGoogleAuth(apiUrl);

    //Toast
    const { showToast, redirectPage } = useAuthRedirect(isStatus201);

    const { addToast } = useToast();

    useEffect(() => {
        if (!state || !code) {
            return;
        }
        authenticateWithGoogle(state, code);
    }, [state, code, authenticateWithGoogle]);

    useEffect(() => {
        if (showToast) {
            addToast({
                message: "Login Successful! Redirecting...",
                bg: "success",
            });
        }
    }, [showToast]);

    useEffect(() => {
        //Trigger redirect to blog url
        if (redirectPage) {
            redirectToBlogURL();
        }
    }, [redirectPage]);

    return (
        <div>
            <button
                className="btn btn-outline-primary col-12 d-grid"
                onClick={continueWithGoogle}
                disabled={loading}
            >
                <span className="me-2">{loading && <LoaderIcon />}</span>
                <span>
                    <i class="fa-brands fa-google me-2"></i>
                    Continue with Google
                </span>
            </button>
        </div>
    );
};

export default GoogleAuthHandler;
