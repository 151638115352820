import AuthUITemplate from "./AuthUI";
import { useEffect } from "react";
import LoaderIcon from "../../cards/utilities/loader";
import { Link, Navigate } from "react-router-dom";
import useResetPassword from "../../../hooks/useResetPassword";
import useAuthRedirect from "../../../hooks/useAuthRedirect";
import { useToast } from "../../../hooks/otherHooks/useToast";

const apiURL = process.env.REACT_APP_API_URL;

const ResetPassword = () => {
    const { email, error, handleChange, handleSubmit, loading, isStatus204 } =
        useResetPassword(apiURL);

    // Toast
    const { showToast, redirectPage } = useAuthRedirect(isStatus204);

    const { addToast } = useToast();

    useEffect(() => {
        if (showToast) {
            addToast({
                message: "Reset Password Email Sent",
                bg: "success",
            });
        }
    }, [showToast, addToast]);

    if (redirectPage) {
        return <Navigate to="/login" />;
    }
    return (
        <AuthUITemplate title="Reset Password" errorMsg={error}>
            <form className="row g-3" onSubmit={(e) => handleSubmit(e)}>
                <section className="col-md-12">
                    <section className="form-floating">
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            name="email"
                            onChange={(e) => handleChange(e)}
                            value={email}
                            required
                        />
                        <label for="email">Email address</label>
                    </section>
                </section>

                <section className="col-12 d-grid">
                    <button
                        type="submit"
                        className={
                            loading
                                ? "btn btn-primary disabled"
                                : "btn btn-primary"
                        }
                    >
                        {loading ? <LoaderIcon /> : null}
                        Reset Password
                    </button>
                </section>
            </form>
            <section class="mt-3">
                <p class="text-center">
                    Already have an account?{" "}
                    <Link to="/login" class="fw-bold text-decoration-none">
                        Log In
                    </Link>
                </p>
            </section>
        </AuthUITemplate>
    );
};

export default ResetPassword;
