import NarBar from "../cards/navBar";
import Footer from "../cards/footer";
import FabIcon from "../cards/utilities/floatingFab";
import ScrollToTop from "../cards/utilities/scrollToTop";

const Layout = () => {
    return (
        <>
            <ScrollToTop />
            <NarBar />

            <Footer />
            <FabIcon />
        </>
    );
};

export default Layout;
